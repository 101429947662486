import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Generative Logo Design`}</h1>
    <p>{`When we first set out to design a logo for Components AI, we naturally gravitated towards generative concepts. The idea of a static mark didn’t seem to make sense as a brand concept. As people interact with the design tools, Components AI as a product is constantly altered. The logo is an opportunity to play with and reflect that concept in our visual language. `}</p>
    <p>{`There are impressive examples of dynamic and `}<a parentName="p" {...{
        "href": "https://www.slideshare.net/grandigrandi/bologna-city-branding-how-to-create-a-generative-logo"
      }}>{`generative branding projects`}</a>{`. As this is just the beginning of our project - something too impressive and complex wouldn’t feel authentic to us. Our instincts were to start with a simple form that could become more complex and technical over time as the project matures and grows. Eventually, the logo design could be informed or driven by data we’ve collected. `}</p>
    <p>{`Our first chats and sketches were centered around dots of random colors on a grid. Even when constraining the colors to a harmonious scale, it was quickly apparent that it wasn't working. Often, the visual contrast between logos was jarring enough to be slightly distracting. `}</p>
    <p>{`Initial sketches on paper were of a 4x4 grid as we’re partial to powers of 2. In practice, it wan’t as aesthetically pleasing as 9 dots. 16 dots was too dense. We compromised on the purity of input values for visual aesthetics. `}</p>
    <p>{`After initial sketches and an evening of light exploration we a bit of informed direction:`}</p>
    <ul>
      <li parentName="ul">{`Enough variance to be different but recognizable `}</li>
      <li parentName="ul">{`Neutral - Not intrusive visually to the canvas`}</li>
      <li parentName="ul">{`Modest / simple form with a lot of room to evolve and grow over time`}</li>
    </ul>
    <p>{`Randomly varying the opacity of each dot on a defined scale was more lackluster than anticipated. The subtle changes in opacity weren’t very perceptible, especially in the corner of the eye. The composition of the form as a whole didn’t feel balanced. Even with light constraints, allowing for the full range of opacity values didn’t seem to work. We shifted to toggling between two values, one dark, one light. We wrote a few different sets of weights that apply to each dot - that are randomly selected before logo generation. `}</p>
    <p>{`Eventually we landed on something that generated the visual states below`}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/v1.jpg",
        "alt": "Examples of Logo v1.0.0"
      }}></img></p>
    <p>{`Since we launched in July it’s served us well. At this stage of the product, logo design is not high on the priority list. While it’s been a short period of time since our launch, some of our technology has evolved past a few milestones. Behind the scenes we’re starting to wire independent systems together. In a manner, dots are beginning to connect. `}</p>
    <p>{`While building the Delaunay triangulation pattern generator, I became interested in the forms generated when there was a small count of coordinates. Using Delaunay to connect a small series of dots, seemed like a small and appropriate evolution in both concept and execution. `}</p>
    <p>{`To visually assess various iterations, the natural first step was to build an interface that could control a generative logo component similar to the other components we have available. The process could be boiled down to this four step process`}</p>
    <ol>
      <li parentName="ol">{`Define the parameters you want to manipulate and see variants of `}</li>
      <li parentName="ol">{`Define lists or sets of input ranges for each input`}</li>
      <li parentName="ol">{`Expose these controls to the GUI `}</li>
      <li parentName="ol">{`Experiment - adjust in code if necessary`}</li>
    </ol>
    <p>{`Often times you want to expand or constrain the inputs into a parameter.
For our purposes here we have a few things to generate that we might want to control: `}</p>
    <ul>
      <li parentName="ul">{`Size of the SVG`}</li>
      <li parentName="ul">{`How many points (coordinates) `}</li>
      <li parentName="ul">{`Border width of outer border (convex hull) `}</li>
      <li parentName="ul">{`Stroke (thickness) of the lines connecting dots (which if could be 0 simulating no line at all)`}</li>
      <li parentName="ul">{`Border width of the points (coordinates)`}</li>
      <li parentName="ul">{`Colors, of course`}</li>
      <li parentName="ul">{`Since the d3-delaunay library renders to svg, there are a number of svg options that could be wired up to controls. For this experiment we tossed in some dash array controls for fun. `}</li>
    </ul>
    <p>{`After the initial grunt work of defining and wiring up the controls - our patience was rewarded with an endless array of options at the click of a mouse. `}</p>
    <p>{`The starting out point proved to have a lot of visual contrast. `}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/step-1.jpg",
        "alt": "Examples of Logo v1.0.0"
      }}></img></p>
    <p>{`We knew we wanted our logo to be a bit more neutral, so we constrained it to something close to black and white. A few things are quickly noticed. First our lower bounds for points count was resulting in triangles - which likely aren’t similar enough to some of the other marks to be recognized as part of the same visual identity.`}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/step-2.jpg",
        "alt": "Examples of Logo"
      }}></img></p>
    <p>{`One of the first issues we found when previewing these as the site logo, was that the variance in shape made the transition between logo updates quite jarring. In addition, we didn’t find most of the forms to be aesthetically pleasing. `}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/step-3.jpg",
        "alt": "Examples of Logo"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/step-2a.jpg",
        "alt": "Examples of Logo"
      }}></img></p>
    <p>{`Constraining the width of the border to 1 or 2 pixels and removing the stroke around points created some visual consistency but still not feeling close to what we want. `}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/step-4.jpg",
        "alt": null
      }}></img></p>
    <p>{`The variance in form here was too much. While not noticeable on a static grid, viewing the logo update inline was continuing to be a subpar experience. To  create some consistency in the overall shape and form, we set the initial coordinates of a square, and then generated additional points inside of this boundary resulting in the below forms.`}</p>
    <p><img parentName="p" {...{
        "src": "https://paper-attachments.dropbox.com/s_4CCE64C174848F10D2C634E5DF4D6EFE0912372951B40D6611E4B68924CB704C_1573437093041_Screen+Shot+2019-11-10+at+7.50.00+PM.png",
        "alt": null
      }}></img></p>
    <p>{`The lower boundary we had set for point count was resulting in some triangles rendering. But some aesthetically pleasing forms have emerged. Things that jumped out where having a balance or slight contrast between the outside border and the line weight. `}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/stained-glass.jpg",
        "alt": null
      }}></img></p>
    <p>{`With more than 10 points, the density and detail got lost at smaller sizes. Almost has stained glass vibes. `}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/dice-block.jpg",
        "alt": null
      }}></img></p>
    <p>{`We cranked up the outer border width and removed lines altogether, just leaving dots for each point. Many of the generated forms felt unintentional - but were also aesthetically pleasing in some cases. Reducing the border and adding the lines back in gave a drastically different look. `}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/dice-lines.jpg",
        "alt": null
      }}></img></p>
    <p>{`As promised for fun, we explored manipulating the lines with dash array. Varying the line-width to extremes on both end lead to some pleasing output that could be explored more in-depth later. They almost resemble paper folding instructions.`}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/dash-array-2.jpg",
        "alt": null
      }}></img></p>
    <p>{`Increasing line width and moving dash array sliders around resulted in some bold marks.`}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/bold.jpg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/noise.jpg",
        "alt": null
      }}></img></p>
    <p>{`This resulted in a more minimalist direction. Again some of the errant lines lead to the marks feeling a bit unintentional. `}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/minimal.jpg",
        "alt": null
      }}></img></p>
    <p>{`A lot of the above explorations, were not within the bounds of our imaginations when we first conceived our logo concept. Even at the point of defining parameters and input values, much of the output is far removed from the original sketches and visual concepts we set out to explore. Some of these iterations helped us define in more particular terms, what we didn’t want or like. `}</p>
    <p>{`Settling on a range of points between 5-7 with an outer border width of 4 and a line width of 1 - we start to generate the forms you see below. These immediately felt 'right.'`}</p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/final-6.jpg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "http://dc28c2r6oodom.cloudfront.net/notes/final-7.jpg",
        "alt": null
      }}></img></p>
    <p>{`After looking at thousands of options, this output felt balanced and inline with what we wanted.
It’s got enough variance to feel alive and dynamic. It’s only 1 degree removed from a random grid of dots. While simple, we feel it’s a step forward aesthetically and a bit more recognizable as a mark. If you'd like to play around with the generator we used to design our current logo - `}<a parentName="p" {...{
        "href": "https://components.ai/logo"
      }}>{`check it out here`}</a>{`. `}</p>
    <p>{`If you are interested in reading more about generative branding, below are some more complex explorations and executions:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.slideshare.net/grandigrandi/bologna-city-branding-how-to-create-a-generative-logo"
      }}>{`https://www.slideshare.net/grandigrandi/bologna-city-branding-how-to-create-a-generative-logo`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.grapheine.com/en/actulogo-en/generative-visual-identity-for-bordeaux-metropole"
      }}>{`https://www.grapheine.com/en/actulogo-en/generative-visual-identity-for-bordeaux-metropole`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://sandrarendgen.wordpress.com/2013/05/03/generative-logos/"
      }}>{`https://sandrarendgen.wordpress.com/2013/05/03/generative-logos/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://dataveyes.com/#!/en/case-studies/identite-generative"
      }}>{`https://dataveyes.com/#!/en/case-studies/identite-generative`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://scss.tcd.ie/publications/theses/diss/2013/TCD-SCSS-DISSERTATION-2013-048.pdf"
      }}>{`https://scss.tcd.ie/publications/theses/diss/2013/TCD-SCSS-DISSERTATION-2013-048.pdf`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.patrik-huebner.com/portfolio-item/generative-logo-synthesizer/"
      }}>{`https://www.patrik-huebner.com/portfolio-item/generative-logo-synthesizer/`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://www.mertl-research.at/design/generative_logo/"
      }}>{`http://www.mertl-research.at/design/generative_logo/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      